import React from "react"
import Card from "react-bootstrap/Card"
import { charte } from "./charte"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"


export const GcCard = (props) => {
  return (<Card align="center" style={{padding: "50px"}}>
    <Card.Img src={props.img} style={{width: "100px" , paddingTop: "20px", paddingBottom: "20px", alignSelf: 'center'}}/>
    <Card.Title align= "center" style={{color: charte.primary}}>{props.title}</Card.Title>
    <Card.Text>{props.content}</Card.Text>
  </Card>)
}

export const Rcard = (props) => {
  return (
    <Card style={{padding: "50px"}}>
    <Row>
      <Col xs={2} align="center">
        <Card.Img src={props.img} variant="center" style={{width: "100px" , paddingTop: "5px", paddingBottom: "5px"}}/>
        <Card.Title align= "center" style={{color: charte.primary}}>{props.title}</Card.Title>
      </Col>
      <Col style={{color: charte.dark}}>
      <Card.Body>
        <Card.Text >{props.content}</Card.Text>
      </Card.Body>
      </Col>
    </Row>
  </Card>)
}