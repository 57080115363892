import React from "react"
import Layout from "../components/layout"
import people from "../icones/group-secondary.svg"
import { GcCard } from "../components/card"
import mission from "../icones/rocket-ship-secondary.svg"
import goal from "../icones/targeting-secondary.svg"
import CardDeck from "react-bootstrap/CardDeck"
import Carousel from "react-bootstrap/Carousel"
import forets from "../images/forets.png"
import fougere from "../images/fougere.png"
import { charte } from "../components/charte"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import labo15 from "../images/labo15.png"
import breakfast from "../images/breakfast.jpeg"
import Image from "react-bootstrap/Image"
import singe from "../images/singe.png"
import survey from "../images/survey.jpg"
import Button from "react-bootstrap/Button"



const IndexPage = () => <Layout fluid>


  <Carousel style={{paddingBottom: "100px"}}>

    <Carousel.Item>
      <Row>
      <Col style = {{paddingLeft: "150px"}}>
        <img src={fougere} className="d-block w-100" height={500}/>
      </Col>
        <Col style = {{paddingRight: "150px"}} align="center">
          <h3 style={{color: charte.primary}}>Tamara Ben Ari and Olivier Berné, co-founders of Labos 1.5</h3>
          <div style={{color: charte.dark}}>Date: December 2nd 2 pm Where: Amphithéatre Maurice Halbwachs</div>
          <div style={{fontSize: "14px", textAlign: "justify"}}>Le récent rapport 1.5 du GIEC ou plus largement la masse d'informations alarmantes sur la rapidité des changements climatiques ou de l'altération des écosystèmes a suscité une inquiétude grandissante dans la société comme en témoignent les mouvements de jeunesse dans le monde entier. Les citoyens sont confrontés quotidiennement à l'accumulation de ces preuves et à leur signification pour leur vie ou celle de leurs enfants. Pour un nombre croissant de scientifiques, cela se traduit également par des changements dans la façon dont ils perçoivent leurs propres pratiques de travail ou leur rôle dans la nécessaire transition écologique des sociétés. Pour répondre à certains de ces défis et avancer, nous avons crée un collectif pour quantifier et réduire l'empreinte environnementale des activités de recherche, et en particulier son empreinte carbone. Nous présenterons les objectifs et les premiers résultats ce collectif qui rassemble aujourd'hui plus de 1300 membres du monde académique dans un large éventail de disciplines en France.</div>
          <img src={labo15} fluid height={50}/>
        </Col>
      </Row>
    </Carousel.Item>

    <Carousel.Item>
      <Row>
        <Col style = {{paddingLeft: "150px"}}>
          <img src={singe} className="d-block w-100" height={500}/>
        </Col>
        <Col style = {{paddingRight: "150px"}} align="center">
          <h3 style={{color: charte.primary}}>Take our survey !</h3>
          <div style={{color: charte.dark}}>2 min long, we swear</div>
          <div style={{fontSize: "14px", textAlign: "center", paddingTop: "20px"}}>To help us have an idea of your idea about the need to change our habits in Research.</div>
          <Col style={{paddingTop: "20px"}}>
          <img src={survey} fluid height={200}/>
          </Col>
          <Col style={{paddingTop: "15px"}}>
          <Button href="https://docs.google.com/forms/d/e/1FAIpQLSewmNEsnxG3SM7OFeN0dyeKIoqZ3OTM4u6e2Non_UhndIpE9w/viewform">Survey</Button>
          </Col>
        </Col>
      </Row>
    </Carousel.Item>

    <Carousel.Item>
      <Row>
        <Col style = {{paddingLeft: "150px"}}>
          <Image src={forets} className="d-block w-100" height={500}/>
        </Col>
        <Col  style = {{paddingRight: "150px"}} align= "center">
          <h3 style={{color: charte.primary}}>Breakfast Club to meet the Com</h3>
          <div style={{color: charte.dark}}>On Nov 7th from 9 am to 10 am in Salle 1</div>
          <div style={{fontSize: "14px", textAlign: "center", paddingTop: "30px"}}>Come and meet us, whatever your current footprint and knowledge of the climate! Bring your cup :) Plastic free aim.</div>
          <Col  style={{paddingTop: "30px"}}>
            <Image src={breakfast} fluid/>
          </Col>
        </Col>
      </Row>
    </Carousel.Item>

  </Carousel>


  <CardDeck>

    <GcCard title="Our Mission" img={mission} content="Make the CIRB community more aware and compliant towards climate change"/>

    <GcCard title="The goals" img={goal} content="Raise awareness and modify our daily habits"/>

    <GcCard title="Join the Team" img={people} content="The more we are, the better: join the team!"/>
  </CardDeck>

</Layout>

export default IndexPage
